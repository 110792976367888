import { Link } from "react-router-dom";
import "./Contact.css";
const Contact = () => {
  return (
    <div className="bg">
      <div className="container mx-auto my-5">
        <h1 className="text-center text-4xl font-bold mb-8 text-green-600 fade-in">
          Contact
        </h1>
        <div className="d-flex flex-column gap-6 contact-card fade-in">
          <div className="contact-item">
            <div className="contact-details  col-12 col-md-4">
              <h4 className="text-lg">
                <span className="">Organizing Secretary:</span>
              </h4>
              <span className=" text-lg text-gray-700">Dr. K T Mahesh</span>
            </div>
            <div className=" col-12 col-md-4 text-center">
              <h4 className="ph_no text-gray-700">+91 9840363642</h4>
            </div>
            <div className="col-12 col-md-4 text-lg-end text-md-end text-center">
              <a className="btn-call" href="tel:+91 9840363642">
                Call Now
              </a>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-details  col-12 col-md-4">
              <h4 className="text-lg">
                <span className="">Scientific Chairman:</span>
              </h4>
              <span className=" text-lg text-gray-700">Dr. C. DEEPAK</span>
            </div>
            <div className=" col-12 col-md-4 text-center">
              <h4 className="ph_no text-gray-700">+91 9840060745</h4>
            </div>
            <div className="col-12 col-md-4 text-lg-end text-md-end text-center">
              <a className="btn-call" href="tel:+91 9840060745">
                Call Now
              </a>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-details  col-12 col-md-4">
              <h4 className="text-lg">
                <span className="text-red-600">
                  Registration &amp; Reception:
                </span>
              </h4>
              <span className="text-lg text-gray-700">
                Dr. Mirnalini Selvakumar
              </span>
            </div>
            <div className=" col-12 col-md-4 text-center">
              <h4 className="ph_no text-gray-700">+91 9940168779</h4>
            </div>
            <div className="col-12 col-md-4 text-lg-end text-md-end text-center">
              <a className="btn-call" href="tel:+91 9940168779">
                Call Now
              </a>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-details  col-12 col-md-4">
              <h4 className="text-lg">
                <span className="">Registration &amp; Reception:</span>
              </h4>
              <span className=" text-lg text-gray-700">Dr. Aravindhan</span>
            </div>
            <div className=" col-12 col-md-4 text-center">
              <h4 className="ph_no text-gray-700">+91 8754583972</h4>
            </div>
            <div className="col-12 col-md-4 text-lg-end text-md-end text-center">
              <a className="btn-call" href="tel:+91 8754583972">
                Call Now
              </a>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-details  col-12 col-md-4">
              <h4 className="text-lg">
                <span className="text-yellow-600">
                  Trade &amp; Sponsorship:
                </span>
              </h4>
              <span className="text-lg text-gray-700">Dr. Deenadayalan.P</span>
            </div>
            <div className=" col-12 col-md-4 text-center">
              <h4 className="ph_no text-gray-700">+91 9840895100</h4>
            </div>
            <div className="col-12 col-md-4 text-lg-end text-md-end text-center">
              <a className="btn-call" href="tel:+91 9840895100">
                Call Now
              </a>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-details  col-12 col-md-4">
              <h4 className="text-lg">
                <span className="text-purple-600">Accommodation:</span>
              </h4>
              <span className="text-lg text-gray-700">Dr. A Victor Samuel</span>
            </div>
            <div className=" col-12 col-md-4 text-center">
              <h4 className="ph_no text-gray-700">+91 9841610525</h4>
            </div>
            <div className="col-12 col-md-4 text-lg-end text-md-end text-center">
              <a className="btn-call" href="tel:+91 9841610525">
                Call Now
              </a>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-details  col-12 col-md-4">
              <h4 className="text-lg">
                <span className="text-orange-600">Transport:</span>
              </h4>
              <span className="text-lg text-gray-700">Dr. Aravindhan.R</span>
            </div>
            <div className=" col-12 col-md-4 text-center">
              <h4 className="ph_no text-gray-700">+91 9790801260</h4>
            </div>
            <div className="col-12 col-md-4 text-lg-end text-md-end text-center">
              <a className="btn-call" href="tel:+91 9790801260">
                Call Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
