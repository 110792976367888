import { Link } from "react-router-dom";
import "./registration.css";
const Registration = () => {
  return (
    <>
      <div className="text-center py-5">
        <h1>Click the below Button to Register</h1>
        <div className="">
          <a
          href="https://forms.eduqfix.com/icddentist/add"
          target="_blank"
          className="registrationbutton"
        >
          Click here
        </a>
        </div>
        
      </div>
    </>
  );
};

export default Registration;
