import "./Committee.css";
const CommitteeMembers = () => {
  return (
    <>
      <div className="container-fluid col-lg-10 ">
        <div className="row pt-5">
          <div className="col-12">
            <h2 className="inter_head">
              INTERNATIONAL COLLEGE OF DENTISTS (ICD) <br />
              ANNUAL MEETING & CONVOCATION
            </h2>
            <p className="inter_parra mt-4">
              <b>VENUE:</b>{" "}
              <span className="span_green">
                SRM Kattankulathur Dental College, SRMIST, Chennai, India.
              </span>
              <br />
            </p>
            <p className="inter_parra">
              <b> DATE:</b>
              <span className="span_green"> FEB 22-23, 2025</span>
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid col-lg-10 py-lg-5">
        <div className="row">
          <div className="col-12">
            <h2 className="organized_head">Organizing Committee - ICD 2025</h2>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-3 col-6">
            <h2 className="chief_head">CHIEF PATRON</h2>

            <h4 className="patron_head mt-3">Dr. T. R. PAARIVENDHAR</h4>

            <p className="patron_parra">
              Founder Chancellor
              <br /> SRMIST
            </p>
          </div>
          <div className="col-lg-3 col-6">
            <h2 className="chief_head">PATRON</h2>

            <h4 className="patron_head mt-3">Dr. RAVI PACHAMOOTHOO </h4>

            <p className="patron_parra">
              Pro-Chancellor - Administration <br /> SRMIST
            </p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <h2 className="chief_head">PATRON</h2>

            <h4 className="patron_head mt-3">Dr. P. SATHYANARAYANAN</h4>

            <p className="patron_parra">
              Pro Chancellor - Academics
              <br /> SRMIST
            </p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <h2 className="chief_head">PATRON</h2>
            <h4 className="patron_head mt-3">Dr. R. SHIVAKUMAR</h4>
            <p className="patron_parra">
              Chairman – Ramapuram and Trichy campuses
              <br /> SRMIST
            </p>
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-lg-3 col-6">
            <h4 className="patron_head mt-3">Dr. C.Muthamizhchelvan</h4>
            <p className="patron_parra">
              Vice Chancellor,
              <br /> SRMIST
            </p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <h4 className="patron_head mt-3">Dr. S. Ponnusamy</h4>
            <p className="patron_parra">
              Registrar,
              <br /> SRMIST
            </p>
          </div>
          <div className="col-lg-3 col-6">
            <h4 className="patron_head mt-3">Dr. Lt Col A. Ravikumar </h4>
            <p className="patron_parra">
              Pro-Vice Chancellor, MHS <br />
              SRMIST
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid col-lg-10 py-lg-5">
        <div className="row d-flex justify-content-center my-5">
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/1.webp"
              className="img-fluid right-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. S.M. BALAJI</h4>
            <p className="patron_parra">
              National
              <br /> Co-Ordinator
            </p>
          </div>
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/2.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. Vivek N</h4>
            <p className="patron_parra">
              Organizing
              <br /> Chairman
            </p>
          </div>
          <div className="col-lg-3 col-6  mt-lg-0 mt-4">
            <img
              src="./img/committee/3.webp"
              className="img-fluid left-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. K. Ravi</h4>
            <p className="patron_parra">
              Organizing
              <br /> Co-Chairman
            </p>
          </div>
        </div>
        <div className="row d-flex justify-content-center my-5">
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/4.webp"
              className="img-fluid right-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. K.T. MAGESH</h4>
            <p className="patron_parra">Organizing Secretary</p>
          </div>
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/5.webp"
              className="img-fluid d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. C. DEEPAK</h4>
            <p className="patron_parra">Scientific Chairman</p>
          </div>
        </div>
      </div>
      <div className="container-fluid col-lg-10 py-lg-5">
        <div className="row">
          <div className="col-12">
            <h2 className="organized_head">Organizing Committee - ICD 2025</h2>
          </div>
        </div>
        <div className="row d-flex justify-content-center my-5">
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/6.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. Argirios Pissiotis,</h4>
            <p className="patron_parra">
              Greece President, <br /> ICD HQ
            </p>
          </div>
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/7.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. Joseph R. Kenneally,</h4>
            <p className="patron_parra">
              USA Secretary General,
              <br />
              ICD HQ
            </p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <img
              src="./img/committee/8.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. MEERA VERMA</h4>
            <p className="patron_parra">President</p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <img
              src="./img/committee/9.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. YOGESH K. VIRMANI</h4>
            <p className="patron_parra">Secretary General</p>
          </div>
        </div>
        <div className="row d-flex justify-content-center my-5">
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/10.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. R.K. BALI</h4>
            <p className="patron_parra">Business Manager</p>
          </div>
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/11.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. ANIL KOHLI</h4>
            <p className="patron_parra">International Councilor</p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <img
              src="./img/committee/12.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. MAHESH VERMA</h4>
            <p className="patron_parra">Chairman, CDE</p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <img
              src="./img/committee/13.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. D.N. KAPOOR Central</h4>
            <p className="patron_parra">Zone - Regent</p>
          </div>
        </div>
      </div>
      <div className="container-fluid col-lg-10 py-lg-5">
        <div className="row">
          <div className="col-12">
            <h2 className="organized_head">Organizing Committee - ICD 2025</h2>
          </div>
        </div>
        <div className="row d-flex justify-content-center my-5">
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/14.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr V. Vidyashree Nandini</h4>
            <p className="patron_parra">Treasurer</p>
          </div>
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/15.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. K. Vijay Venkatesh</h4>
            <p className="patron_parra">Registration & Reception</p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <img
              src="./img/committee/16.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr.P.L.Ravi Shankar </h4>
            <p className="patron_parra">Hospitality & banquet</p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <img
              src="./img/committee/17.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr.Kavitha Ramar </h4>
            <p className="patron_parra">Spouse Committee</p>
          </div>
        </div>
        <div className="row d-flex justify-content-center my-5">
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/18.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. Deenadayalan.P </h4>
            <p className="patron_parra">Trade & Sponsorship</p>
          </div>
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/19.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. A Victor Samuel </h4>
            <p className="patron_parra">Accommodation</p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <img
              src="./img/committee/20.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. Aravindhan.R </h4>
            <p className="patron_parra">Transport</p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <img
              src="./img/committee/21.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. D. Sihivahanan </h4>
            <p className="patron_parra">Website & publicity</p>
          </div>
        </div>
        <div className="row d-flex justify-content-center my-5">
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/22.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. Sibyl. S </h4>
            <p className="patron_parra">Souvenir</p>
          </div>
          <div className="col-lg-3 col-6">
            <img
              src="./img/committee/23.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. Divya. V.C </h4>
            <p className="patron_parra">Inauguration chairman</p>
          </div>
          <div className="col-lg-3 col-6 mt-lg-0 mt-4">
            <img
              src="./img/committee/24.webp"
              className="img-fluid center-block d-block mx-auto"
              alt=""
            />
            <h4 className="patron_head mt-3">Dr. C. Ganesh </h4>
            <p className="patron_parra">Audio Visual</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommitteeMembers;
