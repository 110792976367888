import React from "react";

const ScientificProgram = () => {
  const imgArr = [];
  const detailsArr = [
    {
      name: "Dr Suma Kathigeyan",
      posttion: "Dean",
      college: "Rajah Muthiah Dental College",
      topic: "Analysis of Polymer Additives in Dentistry - A research report",
    },
    {
      name: "Dr U. Arunmozhi",
      posttion: "Principal",
      college: "Sri Venkaterwara Dental College and Hospital ",
    },
    {
      name: "Dr S Nagalaxmi ",
      posttion: "Principal",
      college: "Vivek Ananda Dental college for Women",
    },
    {
      name: "Dr S. Elanchezhiyan",
      posttion: "Principal",
      college: "JKK Natarajah dental college",
    },
    {
      name: "Dr L Deepanandan",
      posttion: "Principal",
      college: "Sri Ramakrishna Dental college and hospital",
    },
    {
      name: "Dr S. Premkumar",
      posttion: "Principal",
      college: "Tamil Nadu Government Dental college",
    },
    {
      name: "Dr A. Murugananthan",
      posttion: "Member of Tamil Nadu Medical Council",
    },
    {
      name: "Dr Alex Mathews Muruppel",
      posttion: "Principal",
      college: "Rajas Dental College and hospital",
    },
    {
      name: "Dr S. Aravind Kumar",
      posttion: "Dean",
      college: "Saveetha Dental College and Hospitals",
    },
    {
      name: "Dr Shahul Hameed Faizee",
      posttion: "Principal",
      college: "Sathyabama University of Dental College and Hospital",
    },
    {
      name: "Dr N Velmurugan",
      posttion: "Principal",
      college: "Meenakshi Ammal Dental College",
    },
    {
      name: "Dr R Hariharan",
      posttion: "Principal",
      college: "Thai Moogambigai Dental college ",
    },
    {
      name: "Dr Puneet Batra",
      posttion: "Principal",
      college: "Manav Rachna Dental College",
    },
    {
      name: "Dr Anusha D",
      posttion: "Professor",
      college: "Sri Ramachandra Medical college",
    },
    {
      name: "Dr Sivapatha Sundharam",
      posttion: "Principal",
      college: "Priyadarshini Dental college and hospital ",
    },
    {
      name: "Dr Vijay V.K",
      posttion: "Principal",
      college: "R.V.S dental college and hospital ",
    },
    {
      name: "Dr M S Kannan",
      posttion: "Principal",
      college: "Sree Balaji Dental College and hospital.",
      topic: "Paradigm Shift In Today’s Orthodontics with AI",
    },
    {
      name: "Dr. H. Thamizhchelvan",
      posttion: "Principal",
      college: "Sri Ramachandra Dental college and hospital ",
    },
    {
      name: "Dr S.P.K. Kennedy Babu",
      posttion: "Dean",
      college: "Mahatma Gandhi Postgraduate Institute of Dental Sciences",
    },
    {
      name: "Dr Anil Kumar ",
      posttion: "Principal",
      college: "Ragas Dental college and Hospital",
    },
    {
      name: "Dr  C. J. Venkatakrishnan",
      posttion: "Principal",
      college: "Tagore Dental College",
    },
    {
      name: "D. J. Baby John",
      posttion: "Principal",
      college:
        "Vinayaka Missions Sankarachariyar Dental College and Hospital,Salem, Tamil Nadu.",
    },
  ];
  for (let i = 1; i < 23; i++) {
    imgArr.push({
      src: `./img/speakers/speaker${i}.webp`,
      description: detailsArr[i - 1],
    });
  }
  console.log(imgArr.src);
  return (
    <div>
      <section className="container-fluid my-5 py-5 col-lg-10 col-md-10 col-11">
        <h1 className="text-center inter_head">SPEAKER</h1>
        <div className="row mt-4 py-4" id="imageGrid">
          {imgArr.map((data, i) => {
            return (
              <div
                key={i}
                className="flex align-items-center justify-content-center flex-column py-4 col-12 col-md-6 col-lg-4"
              >
                <img
                  key={i}
                  style={{ cursor: "pointer" }}
                  className="mx-auto rounded img-fluid d-block py-2 center-block animatedimgs"
                  width="80%"
                  src={data.src}
                  alt="hiii"
                />
                <div className="py-2 px-2">
                  <p className="text-center mb-0 patron_head">{data.description?.name}</p>
                  <p className="text-center mb-1 ">
                    {data.description?.posttion}
                  </p>
                  <p className="text-center mb-0 ">
                    {data.description?.college}
                  </p>
                  <p className="text-center mb-0 ">{data.description?.topic}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default ScientificProgram;
