import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Committee from "./pages/Committee/Committee";
import Contact from "./pages/Contact/Contact";
import Abstracts from "./pages/Abstract/Abstracts";
import Registraction from "./pages/Registration/Registration";
import Accommodation from "./pages/Accommodation/Accommodation";
import Layout from "./component/Layout";
import Login from "./pages/Loin/Login";
import Trade from "./pages/Trade/Trade";
import { Routes, Route, Navigate } from "react-router-dom";
import AbstractSubmission from "./component/AbstractSubmission/AbstractSubmission";
import CaseReport from "./component/AbstractSubmission/CaseReport";
import Series from "./component/AbstractSubmission/Series";
import Scientificprogram from "./pages/scientificProgram/ScientificProgram";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="committee" element={<Committee />} />
          <Route path="registration" element={<Registraction />} />
          <Route path="Abstracts" element={<Abstracts />} />
          <Route path="accommodation" element={<Accommodation />} />
          <Route path="trade" element={<Trade />} />
          <Route path="contact" element={<Contact />} />
          <Route path="login" element={<Login />} />
          <Route path="abstractsubmission" element={<AbstractSubmission />} />
          <Route path="casereport" element={<CaseReport />} />
          <Route path="series" element={<Series />} />
          <Route path="scientificprogram" element={<Scientificprogram />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
