import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Nav.css";

const Nav = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const HandleMenu = () => {
    setOpenMenu(false);
  };
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  console.log(pathname);
  return (
    <>
      <nav className="navbar navbar-expand-lg fixed sticky-top desktop_navbar">
        <div className="container-fluid col-12">
          <Link className="navbar-brand" to="/">
            <img
              src="./img/home/logo.webp"
              alt="ICD International College of Dentists (REGD)"
              className="img-fluid logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse d-flex justify-content-start"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className={`nav-link ${pathname === "" ? "active" : ""}`}
                  to={"/"}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${pathname === "about" ? "active" : ""}`}
                  to={"/about"}
                >
                  About
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${pathname === "committee" ? "active" : ""
                    }`}
                  to={"/committee"}
                >
                  Committee
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${pathname === "scientificprogram" ? "active" : ""
                    }`}
                  to={"/scientificprogram"}
                >
                  Scientific Program
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className="nav-link"
                  target="_blank"
                  to="https://forms.eduqfix.com/icddentist/add"
                >
                  Registration
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${pathname === "Abstracts" ? "active" : ""
                    }`}
                  to={"/Abstracts"}
                >
                  Abstract Submission
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${pathname === "accommodation" ? "active" : ""
                    }`}
                  to={"/accommodation"}
                >
                  Accommodation and Transportation
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link className="nav-link" to={"/"}>
                  Trade
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link className="nav-link" to={"/contact"}>
                  Contact
                </Link>
              </li>
              {/* <li className="nav-item mx-1">
                <Link className="nav-link" to={"/"}>
                  Login
                </Link>
              </li>
              <li className="nav-item mx-1">
                <button className="btn_dowload">
                  Dowload<i className="bi bi-box-arrow-down mx-2"></i>
                </button>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <div className="mobile_container">
        <div className={`header_mobile ${openMenu ? "menu-open" : null}`}>
          <div className="container-fluid col-lg-10">
            <div className="d-flex justify-content-between align-items-center mobile_pallete">
              <div>
                <Link to="/home">
                  <img
                    src="./img/home/logo.webp"
                    className="img-fluid logo ml-5"
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="icon-container mr-5">
                <i
                  className="bi bi-list hamburger"
                  onClick={() => setOpenMenu(!openMenu)}
                ></i>
              </div>
            </div>
          </div>
          <div className="mobile-menu">
            <ul className="menu">
              <li className="menu-item">
                <Link to={"/"} onClick={() => HandleMenu()}>
                  Home
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/about"} onClick={() => HandleMenu()}>
                  About
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/committee"} onClick={() => HandleMenu()}>
                  Committee
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  target="_blank"
                  to="https://forms.eduqfix.com/icddentist/add"
                  onClick={() => HandleMenu()}>
                  Registration
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/scientificprogram"} onClick={() => HandleMenu()}>
                  Scientific Program
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/Abstracts"} onClick={() => HandleMenu()}>
                  Abstract Submission
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/accommodation"} onClick={() => HandleMenu()}>
                  Accommodation and Transportation
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/"} onClick={() => HandleMenu()}>
                  Trade
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/contact"} onClick={() => HandleMenu()}>
                  Contact
                </Link>
              </li>

              <li className="menu-item">
                <div className="icon-container1 mt-4">
                  <i
                    className="bi bi-x-circle closed"
                    onClick={() => HandleMenu()}
                  ></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
